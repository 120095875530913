import React, { useState, useRef, useEffect } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.primary.main,
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    marginBottom: "8px",
    letterSpacing: 0,
    width: "100%",
    fontWeight: "400",
    fontSize: "13px",
    "&:hover": {
      color: "#ffff",
      backgroundColor: "rgb(21, 80, 128)",
      borderRadius: "5px",
      "& $icon": {
        color: "#ffff",
      },
    },
  },
  buttonLeaf: {
    color: "#3e3f5e",
    padding: "13px 20px",
    margin: "7px",
    borderRadius: "8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    marginBottom: "5px",
    borderRadius: 0,
    opacity: 1,
    borderRadius: "0px",
    fontWeight: "700",
    fontSize: "13px",
    borderRadius:'8px',
    "& li": {
      "& $title": {
        marginLeft: "30px",
      },
    },
    "&:hover": {
      color: "#ffff",
      backgroundColor: "#615dfa",
      boxShadow: "4px 7px 12px 0 rgba(97, 93, 250, 0.2)",
      borderRadius: "8px",
      "& $icon": {
        color: "#ffff",
      },
    },
    "&.depth-0": {
      "& $title": {
        whiteSpace: "pre",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: "#3e3f5e",
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: "#ffff",
    backgroundColor: "#615dfa",
    boxShadow: "4px 7px 12px 0 rgba(97, 93, 250, 0.2)",
    borderRadius: "8px",
    "& $icon": {
      color: "#ffff",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  check,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  const itemRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (itemRef.current && location.pathname === href) {
        itemRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [location.pathname, href]);
  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }


  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        {check === true && (
          <Button className={classes.button} onClick={handleToggle}>
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        )}

        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <>
      {check === true && (
        <ListItem
          className={clsx(classes.itemLeaf, className)}
          disableGutters
          key={title}
          ref={itemRef}
          {...rest}
        >
          <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={RouterLink}
            exact
            to={href}
          >
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
            {Info && <Info />}
          </Button>
        </ListItem>
      )}
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
